import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Inicio from '../views/Inicio.vue'
import ServiciosDesign from '../views/ServiciosDesign.vue'
import ServiciosConstruccion from '../views/ServiciosConstruccion.vue'
import Proyectos from '../views/Proyectos.vue'
import Encuestas from '../views/Encuestas.vue'
import Contacto from '../views/Contacto.vue'
import Cv from '../views/Cv.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Inicio',
    component: Inicio
  },  
  {
    path: '/ServiciosDesign',
    name: 'ServiciosDesign',
    component: ServiciosDesign
  },  
  {
    path: '/ServiciosConstruccion',
    name: 'ServiciosConstruccion',
    component: ServiciosConstruccion
  },  
  {
    path: '/Proyectos',
    name: 'Proyectos',
    component: Proyectos
  },   
  {
    path: '/Encuestas',
    name: 'Encuestas',
    component: Encuestas
  }, 
  {
    path: '/Contacto',
    name: 'Contacto',
    component: Contacto
  },
  {
    path: '/Cv',
    name: 'Cv',
    component: Cv
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
