











































































































































import { Component, Vue } from 'vue-property-decorator';
import ImageCard from '../components/ImageCard.vue'
import FooterContact from '../components/FooterContact.vue'

@Component({
  components: {
    ImageCard,
    FooterContact,
  },
  data (){
    return {
    }
  }
})
export default class ServiciosConstruccion extends Vue {}
