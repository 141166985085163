

















































































































import Vue from 'vue'

export default Vue.extend({
  name: 'Navbar',

  data: () => ({
    showMenu: false,
    group: null,
    drawer: false,
    subMenu: false,
  }),
})
