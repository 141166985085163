
































import Vue from 'vue'

export default Vue.extend({
  name: 'ImageCard',

  data: () => ({
  }),
  props: {
    title: String,
    subtitle: String,
    imgUrl: String,
    actionTo: String,
    actionName: String,
    flat: {
      type: Boolean,
      default: false
    },
    maxWith: {
      type: String,
      default: '100'
    }
  }
})
